<template>
    <el-dialog :close-on-click-modal="false" title="会员列表" :visible.sync="mbVisible" width="800px"
               :before-close="closeEvent">
        <div class="mbs">
            <div class="mbs-items">
                <div class="mbs-mb" v-if="mbs.length>0" v-for="item in mbs">
                    <div>
                        <img v-if="!item.headPortrait&&item.sex == 0" src="../../../assets/images/nan.png"
                             class="mb-img">
                        <img v-if="!item.headPortrait&&item.sex == 1" src="../../../assets/images/nan.png"
                             class="mb-img">
                        <img v-if="!item.headPortrait&&item.sex == 2" src="../../../assets/images/nv.png"
                             class="mb-img">
                        <img v-if="item.headPortrait" :src="item.headPortrait" class="mb-img">
                    </div>
                    <div style="display:block;margin-left: 8px;width: 100%;cursor: pointer" @click="setMember(item)">
                        <div class="mbs-name">
                            <span>{{item.memberName}}</span>
                            <span style="margin-left: 10px">{{item.phone}}</span>
                        </div>
                        <div class="mbs-item" v-if="item.memberCards.length>0">
                            <span>会员卡: {{item.memberCards[0].cardName}}</span>
                            <span>余额: {{item.memberCards[0].balance}}<span style="margin-left: 18px">赠送金: {{item.memberCards[0].giveBalance}}</span></span>
                        </div>
                        <div class="mbs-item" v-if="item.memberCards.length<=0">
                            <span>会员卡: 无</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mbs-box">
                <span class="v-form-label">手机号后四位 / 姓名:</span>
                <el-input placeholder="请输入手机号后四位 / 姓名" size="mini" style="width: 265px; display: inline-block"
                          v-model="money"
                          clearable
                          class="handle-input mr10"
                          @keyup.enter.native="searchEvent"
                ></el-input>
                <el-row type="flex" ustify="end" style="margin-left: 30px; display: inline-block">
                    <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询</el-button>
                </el-row>
            </div>
        </div>
        <div style="margin-bottom: 38px">
            <div class="mbs-tab" v-if="showList">
                <el-table
                        :data="members"
                        height="400px"
                        style="width: 100%">
                    <el-table-column label="图片" prop="id" align="center">
                        <template slot-scope="scope">
                            <div>
                                <img v-if="!scope.row.headPortrait&&scope.row.sex == 0" src="../../../assets/images/nan.png" class="mb-img">
                                <img v-if="!scope.row.headPortrait&&scope.row.sex == 1" src="../../../assets/images/nan.png" class="mb-img">
                                <img v-if="!scope.row.headPortrait&&scope.row.sex == 2" src="../../../assets/images/nv.png" class="mb-img">
                                <img  v-if="scope.row.headPortrait" :src="scope.row.headPortrait" class="mb-img">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="名称" prop="memberName"/>
                    <el-table-column label="性别" prop="sex">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sex==0">未知</span>
                            <span v-if="scope.row.sex==1">男</span>
                            <span v-if="scope.row.sex==2">女</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="手机号" prop="phone"/>
                    <el-table-column label="操作" prop="sex">
                        <template slot-scope="scope">
                            <el-button size="mini" class="e-button" type="primary" @click="setMember(scope.row)">选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                            background
                            @current-change="handleCurrentChange"
                            :pager-count="7"
                            layout="total,prev, pager, next"
                            :total="pagination.count"
                            :page-count="pagination.pages"
                            :current-page="pagination.pageNumber"
                    ></el-pagination>
                </div>
            </div>

            <div class="mb-container" v-if="!showList">
                <div class="keyboard" @click.stop="_handleKeyPress">
                    <div class="key-row">
                        <div class="key-cell" data-num="1">1</div>
                        <div class="key-cell" data-num="2">2</div>
                        <div class="key-cell" data-num="3">3</div>
                        <div class="key-cell el-icon-back" data-num="D"></div>
                    </div>
                    <div class="key-row">
                        <div class="key-cell" data-num="4">4</div>
                        <div class="key-cell" data-num="5">5</div>
                        <div class="key-cell" data-num="6">6</div>
                        <div class="key-cell el-icon-close" data-num="C"></div>
                    </div>
                    <div class="key-row">
                        <div class="key-cell" data-num="7">7</div>
                        <div class="key-cell" data-num="8">8</div>
                        <div class="key-cell" data-num="9">9</div>
                        <div class="key-cell" data-num="0">0</div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
    import {getToken} from "../../../utils/auth";

    export default {
        data() {
            return {
                money: "",

                token: '',
                userId: '',
                members: [],
                pagination: {
                    count: 0,
                    pageNumber: 0,
                    pageSize: 0,
                    pages: 1,
                },

                showList: false,
                mbs: [],
            };
        },
        props: {
            mbVisible: Boolean,
        },
        watch: {
            money(val) {
                if (!val) {
                    this.showList = false;
                }
            },
        },
        created() {
            this.token = getToken()
            this.getRecentlyMembers()
        },
        methods: {
            async getRecentlyMembers() {
                let data = {
                    token: this.token,
                };
                let res = await this.$get("/sw/getRecentlyMembers", data);
                this.mbs = res.list
                console.log(res)
            },
            _handleKeyPress(e) {
                let num = e.target.dataset.num;
                if (
                    e.target.className == "key-cell" ||
                    e.target.className == "key-cell el-icon-back" ||
                    e.target.className == "key-cell el-icon-close" ||
                    e.target.className == "key-cell el-icon-check"
                ) {
                    //不同按键处理逻辑
                    // -1 代表无效按键，直接返回
                    if (num == -1) return false;
                    switch (String(num)) {
                        //删除键
                        case "D":
                            this._handleDeleteKey();
                            break;
                        //清空键
                        case "C":
                            this._handleClearKey();
                            break;
                        default:
                            this._handleNumberKey(num);
                            break;
                    }
                }
            },
            //处理删除键
            _handleDeleteKey() {
                let S = this.money;
                //如果没有输入，直接返回
                if (!S.length) return false;
                //否则删除最后一个
                this.money = S.substring(0, S.length - 1);
            },
            //处理清空键
            _handleClearKey() {
                this.money = "";
            },

            //处理数字
            _handleNumberKey(num) {
                let S = this.money;
                this.money = S + num;
            },


            closeEvent() {
                this.$emit("closeML");
            },

            toAddMember() {
                this.$emit("toAddMember");
            },
            setMember(mbItem) {
                this.$emit("setMember", mbItem);
            },

            searchEvent() {
                this.getMembers();
            },

            handleCurrentChange(page) {
                this.getMembers(page);
            },

            async getMembers(page) {
                if (!this.money) {
                    this.$message.error("请输入要搜索的手机号 / 姓名!");
                    return;
                }
                let currentPage = page || 1;
                let data = {
                    token: this.token,
                    name: this.money,
                    isDel: 0,
                    size: 10,
                    currentPage,
                };
                let res = await this.$get("/sw/getSwMemberForPage", data);
                if (res.code == 200) {
                    this.showList = true;
                    this.members = res.dataSet.list;
                    this.pagination = {
                        count: res.dataSet.count,
                        pageNumber: res.dataSet.pageNumber,
                        pageSize: res.dataSet.pageSize,
                        pages: res.dataSet.pages,
                    };
                }
            },

        },
    };
</script>
<style lang="scss" scoped>
    .mbs {
        text-align: center;
        margin-top: -10px;
    }

    .mbs-items {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        margin-bottom: 30px;
    }

    .mbs-mb {
        width: 33%;
        display: flex;
        flex-direction: row;
        text-align: left;
        font-size: 10px;
        margin: 0px 10px
    }

    .mb-img {
        width: 45px;
        height: 45px;
        float: left;
        border-radius: 30px;
        border: #E6E6E6 solid 1px
    }

    .mbs-name {
        width: 100%;
        height: 20px;
        line-height: 20px;
    }
    .mbs-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 20px;
        line-height: 20px;
    }

    .mbs-tab {
        width: 100%;
        height: 400px;
        margin-top: 16px;
    }

    .tab-img {
        width: 30px;
        height: 30px;
        display: block;
        align-items: center;
        margin: auto;
    }

    ::v-deep.el-table th, .el-table td {
        padding: 5px 0;
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
    }

    ::v-deep .el-table__header tr,
    .el-table__header th {
        padding: 0;
        height: 32px;
        font-size: 13px;
        text-align: center;
    }

    //调整表格高度
    ::v-deep .el-table__body tr,
    .el-table__body td {
        padding: 0;
        height: 32px;
        font-size: 13px;
    }

    ::v-deep .pagination {
        margin: 5px 0;
        text-align: right;
    }

    ::v-deep .el-table td {
        padding: 5px 0;
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
        font-size: 13px;
    }

    /*会员套餐详情*/
    .table-header {
        text-align: left;
        width: 99.5%;
        height: 50px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.03);

        .fudong {
            display: inline-block;
            width: 150px;
            height: 50px;
            float: left;
            text-align: center;
            line-height: 50px;
        }
    }

    .table-header:hover {
        background-color: #ececec;
    }

    .mb-container {
        text-align: center;
        height: 328px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .key-cell {
        display: inline-block;
        width: 68px;
        height: 68px;
        line-height: 68px;
        border-radius: 50%;
        border: #222222 solid 1px;
        margin-right: 10px;
        margin-top: 10px;
        font-weight: bold;
        font-size: 22px;
    }

</style>
